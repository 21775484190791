var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[(_vm.isManager)?_c('div',{staticClass:"form-head d-flex mb-3 align-items-start"},[_c('div',{staticClass:"mr-auto d-lg-block"}),_c('b-button',{attrs:{"variant":"outline-info","to":"/report/team-visit"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"person-bounding-box","aria-hidden":"true","animation":"fade"}}),_vm._v(" Data Team Outlet ")],1)],1):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"basic-form"},[_c('form',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-md-4 col-lg-3"},[_c('label',[_vm._v("Start Date")]),_c('b-form-datepicker',{staticStyle:{"border-width":"initial","height":"56px !important"},attrs:{"date-format-options":{
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                    },"locale":"id"},on:{"input":_vm.onChangeDate},model:{value:(_vm.formInput.date_start),callback:function ($$v) {_vm.$set(_vm.formInput, "date_start", $$v)},expression:"formInput.date_start"}})],1),_c('div',{staticClass:"col-sm-6 col-md-4 col-lg-3"},[_c('label',[_vm._v("End Date")]),_c('b-form-datepicker',{staticStyle:{"border-width":"initial","height":"56px !important"},attrs:{"date-format-options":{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    },"locale":"id","min":_vm.formInput.date_end},on:{"input":_vm.onChangeDate},model:{value:(_vm.formInput.date_end),callback:function ($$v) {_vm.$set(_vm.formInput, "date_end", $$v)},expression:"formInput.date_end"}})],1)])])])]),_c('div',{staticClass:"card-footer border-0 pt-0"},[_c('p',{staticClass:"card-text d-inline h4"},[_vm._v("Total Outlet: "+_vm._s(_vm.rows.length))])])])]),(_vm.rows.length > 0)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"styleClass":"display mb-4 dataTable no-footer","pagination-options":{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
              },"rows":_vm.rows,"columns":_vm.filterColumns,"compactMode":""},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('div',[_c('b-button-group',[_c('b-button',{attrs:{"title":"Detail","variant":"outline-info","size":"sm","to":'detail-visit?id=' + props.row.id}},[_c('i',{staticClass:"fa fa-search"}),_vm._v(" Detail ")])],1)],1):(props.column.field == 'iteration')?_c('div',[_vm._v(" "+_vm._s(props.row.originalIndex + 1)+". ")]):(props.column.field == 'document.additional_info')?_c('div',[_c('div',{staticClass:"mail-list"},_vm._l((props.row.document.additional_info),function(v,i){return _c('div',{key:i,staticClass:"list-group-item"},[_c('span',{staticClass:"icon-warning"},[_c('i',{staticClass:"fa fa-circle",attrs:{"aria-hidden":"true"}})]),_vm._v(" "+_vm._s(v.kolom_field)+": "),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(v.kolom_value))])])}),0)]):(props.column.field == 'latitude')?_c('div',[_c('a',{staticClass:"btn btn-xs btn-outline-warning",attrs:{"href":'https://www.google.com/maps/place/' + props.row.latitude + '+' + props.row.longitude,"target":"_blank"}},[_vm._v(" Map ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,526166768)})],1)])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',[_c('h6',{staticClass:"card-title"},[_vm._v("Outlet")])])])}]

export { render, staticRenderFns }