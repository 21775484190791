<template>
<div>
    <b-modal id="modal-form" ref="my-modal-form" :title="title" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" button-size="sm" @hidden="$parent.handleCancel">
        <form @submit.prevent="handleSubmit">
            <div class="form-row" v-if="type == 'marketing;'">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="text text-info">Hospital</label>
                        <v-select taggable :options="hospitals" v-model="formInput.hospital_name" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="text text-info">User</label>
                        <v-select taggable :options="doctors" v-model="formInput.doctor_name" />
                    </div>
                </div>
                <div class="col-md-6 col-lg-5">
                    <div class="form-group">
                        <label class="text text-info">Position</label>
                        <input type="text" class="form-control" v-model="formInput.specialist" name="specialist" />
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label class="text text-info">Date</label>
                        <b-form-datepicker id="example-datepicker" v-model="formInput.tanggal" class="mb-2"></b-form-datepicker>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="form-group">
                        <label class="text text-info">Time</label>
                        <b-form-timepicker v-model="formInput.time" locale="id"></b-form-timepicker>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="text text-info">Purpose</label>
                        <textarea rows="4" class="form-control" v-model="formInput.purpose"></textarea>
                    </div>
                </div>
            </div>

            <div class="form-row" v-else>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="text text-info">Place Type</label>
                        <v-select :options="places" v-model="formDental.type_place" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="text text-info">Place Name</label>
                        <v-select taggable :options="hospitals" v-model="formDental.name_place" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="text text-info">PIC</label>
                        <v-select taggable :options="doctors" v-model="formDental.name_pic" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="text text-info">Date</label>
                        <b-form-datepicker id="example-datepicker" v-model="formDental.tanggal" class="mb-2"></b-form-datepicker>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="text text-info">Time</label>
                        <b-form-timepicker v-model="formDental.time" locale="id"></b-form-timepicker>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="text text-info">Phone Number</label>
                        <input type="number" class="form-control" v-model="formDental.phone_number">
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group">
                        <label class="text text-info">Address</label>
                        <input type="text" class="form-control" v-model="formDental.address">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="text text-info">Activities</label>
                        <input type="text" class="form-control" v-model="formDental.activities">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="text text-info">Purpose</label>
                        <textarea rows="4" class="form-control" v-model="formDental.etc"></textarea>
                    </div>
                </div>

            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
    BFormTimepicker
} from 'bootstrap-vue'

export default {
    name: "FormPlanning",
    components: {
        BFormTimepicker
    },
    data() {
        return {
            title: 'Add Planning',
            formInput: {
                type_doc: "",
                title: "",
                hospital_name: "",
                doctor_name: "",
                specialist: "",
                tanggal: "",
                icon: "",
                time: "",
                purpose: ""
            },
            formDental: {
                icon: "",
                type_doc: "",
                type_place: "",
                name_place: "",
                name_pic: "",
                address: "",
                phone_number: "",
                activities: "",
                tanggal: "",
                waktu: "",
                etc: ""
            },
            type: '',
            icon: '',
            hospitals: [],
            doctors: [],
            places: []
        };
    },
    props: {
        propsRow: {
            type: Object,
        },
        typeDoc: {
            type: Object
        }
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                if (this.propsRow.id != undefined) {
                    this.title = 'Update Planning'
                    if (this.type == 'marketing_activity') {
                        this.formInput.id = this.propsRow.id
                        this.formInput.type_doc = this.propsRow.document_planning.type_doc_planning
                        this.formInput.hospital_name = this.propsRow.document_planning.hospital_name
                        this.formInput.doctor_name = this.propsRow.document_planning.doctor_name
                        this.formInput.specialist = this.propsRow.document_planning.specialist
                        this.formInput.tanggal = this.propsRow.document_planning.tanggal
                        this.formInput.time = this.propsRow.document_planning.waktu
                        this.formInput.purpose = this.propsRow.document_planning.purpose
                        this.formInput.icon = this.propsRow.icon
                    } else if (this.type == 'dental_activity') {
                        this.formDental.icon = this.propsRow.icon
                        this.formDental.type_doc = this.propsRow.document_planning.type_doc_planning
                        this.formDental.id = this.propsRow.id
                        this.formDental.type_place = this.propsRow.document_planning.type_place
                        this.formDental.name_place = this.propsRow.document_planning.name_place
                        this.formDental.name_pic = this.propsRow.document_planning.name_pic
                        this.formDental.address = this.propsRow.document_planning.address
                        this.formDental.phone_number = this.propsRow.document_planning.phone_number
                        this.formDental.activities = this.propsRow.document_planning.activities
                        this.formDental.tanggal = this.propsRow.document_planning.tanggal
                        this.formDental.waktu = this.propsRow.document_planning.waktu
                        this.formDental.etc = this.propsRow.document_planning.etc
                    }
                } else {
                    this.title = 'Add Planning';
                    this.resetForm()
                }
            },
        },
        typeDoc: {
            immediate: true,
            handler() {
                this.formInput.icon = this.typeDoc.icon
                this.formInput.type_doc = this.typeDoc.type_doc
                this.icon = this.typeDoc.icon
                this.type = this.typeDoc.type_doc
            }
        }
    },
    mounted() {
        this.getPlace()
        this.getHospital()
        this.getDoctor()
    },
    methods: {
        resetForm() {
            this.formInput = {
                type_doc: this.formInput.type_doc,
                title: "",
                hospital_name: "",
                doctor_name: "",
                specialist: "",
                tanggal: "",
                icon: this.formInput.icon,
                time: ""
            }

            this.formDental = {
                type_doc: this.formDental.type_doc,
                icon: this.formDental.icon,
                type_place: "",
                name_place: "",
                name_pic: "",
                address: "",
                phone_number: "",
                activities: "",
                tanggal: "",
                waktu: "",
                etc: ""
            }
        },
        async getPlace() {
            try {
                let resp = await axios.get("report-service/search/place", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.places = resp.data.data
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async getHospital() {
            try {
                let resp = await axios.get("report-service/search/hospital", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.hospitals = resp.data.data
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async getDoctor() {
            try {
                let resp = await axios.get("report-service/search/user-doctor", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.doctors = resp.data.data
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);

                let url = ''
                let formData = {}

                if (this.type == 'marketing_activity') {
                    url = this.formInput.id === undefined ? "report-service/document/planning/create" : "report-service/document/planning/update"
                    this.formInput.icon = this.icon
                    this.formInput.type_doc = this.type
                    formData = this.formInput
                } else if (this.type == 'dental_activity') {
                    url = this.formDental.id === undefined ? "report-service/document/planning/create" : "report-service/document/planning/update"
                    formData = {
                        tipe_tempat: this.formDental.type_place,
                        nama_tempat: this.formDental.name_place,
                        nama_pic: this.formDental.name_pic,
                        nomor_handphone: this.formDental.phone_number,
                        alamat: this.formDental.address,
                        activities: this.formDental.activities,
                        etc: this.formDental.etc,
                        tanggal: this.formDental.tanggal,
                        time: this.formDental.waktu,
                        icon: this.icon,
                        type_doc: this.type,
                    }

                    if (this.formDental.id !== undefined) {
                        formData.id = this.formDental.id
                    }
                }

                const resp = await axios.post(url, formData, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.$bvModal.hide("modal-form");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.resetForm()

                    this.$parent.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
