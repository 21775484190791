<template>
<div>
    <b-modal id="modal-form-send-email" ref="my-modal-form-send-email" :title="title" size="md" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" button-size="sm" @hidden="$parent.handleCancel">
        <div class="profile-skills mb-3">
            <p class="text-info mb-2">Send from date: </p>
            <button type="button" readonly class="btn btn-info light btn-xs mb-1">{{ dateStart }}</button>
            &ndash;
            <a href="javascript:void()" class="btn btn-info light btn-xs mb-1">{{ dateEnd }}</a>
        </div>
        <form @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="text text-info">Send To</label>
                        <v-select label="first_name" :options="users" v-model="selected.user" @input="v => v == null ? formInput.id_user = '' : formInput.id_user = v.id">
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ option.first_name + ' ' + option.last_name }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.first_name + ' ' + option.last_name }}
                                </div>
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "FormSendEmail",
    data() {
        return {
            title: 'Send Planning',
            formInput: {
                date_from: "",
                date_to: "",
                id_user: "",
                type_doc: "",
            },
            selected: {
                users: ""
            },
            users: []
        };
    },
    props: {
        dateStart: {
            type: String,
        },
        dateEnd: {
            type: String,
        },
        typeDoc: {
            type: Object
        }
    },
    watch: {
        dateStart: {
            immediate: true,
            handler() {
                this.formInput.date_from = this.dateStart;
            },
        },
        dateEnd: {
            immediate: true,
            handler() {
                this.formInput.date_to = this.dateEnd;
            },
        },
        typeDoc: {
            immediate: true,
            handler() {
                this.formInput.type_doc = this.typeDoc.type_doc
            }
        }
    },
    mounted() {
        this.getLeader()
    },
    methods: {
        async getLeader() {
            try {
                let resp = await axios.get("user-service/auth/group/my-leader", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.users = resp.data.data.users
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },

        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "report-service/document/planning/export",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$bvModal.hide("modal-form-send-email");
                    this.$swal.fire("Success", resp.data.message, "success");
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
