<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start" v-if="isManager">
        <div class="mr-auto d-lg-block"></div>
        <b-button variant="outline-info" to="/report/team-survey">
            <b-icon icon="person-bounding-box" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Team Survey
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h6 class="card-title">Survey</h6>
                    </div>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="row">
                                <div class="col-sm-6 col-md-4 col-lg-3">
                                    <label>Start Date</label>
                                    <b-form-datepicker @input="onChangeDate" :date-format-options="{
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                    }" locale="id" v-model="formInput.date_start" style="border-width: initial; height: 56px !important">
                                    </b-form-datepicker>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-3">
                                    <label>End Date</label>
                                    <b-form-datepicker @input="onChangeDate" :date-format-options="{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    }" locale="id" v-model="formInput.date_end" :min="formInput.date_end" style="border-width: initial; height: 56px !important">
                                    </b-form-datepicker>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card-footer border-0 pt-0">
                    <p class="card-text d-inline h4">Total Survey: {{ rows.length }}</p>
                </div>
            </div>
        </div>
        <div class="col-12" v-if="rows.length > 0">
            <div class="card">
                <div class="card-body">
                    <vue-good-table styleClass="display mb-4 dataTable no-footer" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
              }" :rows="rows" :columns="filterColumns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button-group>
                                    <b-button title="Detail" variant="outline-info" size="sm" :to="'detail-survey?id=' + props.row.id"><i class="fa fa-search"></i> Detail
                                    </b-button>
                                </b-button-group>
                            </div>

                            <div v-else-if="props.column.field == 'iteration'">
                                {{ props.row.originalIndex + 1 }}.
                            </div>

                            <div v-else-if="props.column.field == 'document.picture'">
                                <img class="img-fluid rounded" width="35" height="40" style="object-fit: cover;" :src="props.row.document.picture" alt="Foto">
                            </div>

                            <div v-else-if="props.column.field == 'latitude'">
                                <a class="btn btn-xs btn-outline-warning" :href="'https://www.google.com/maps/place/' + props.row.latitude + '+' + props.row.longitude " target="_blank"> Map
                                </a>
                            </div>

                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

import "bootstrap-vue/dist/bootstrap-vue.css";

const moment = require("moment");

const it_activity = [{
        label: "#",
        field: "iteration",
        sortable: false,
        hidden: false,
    },
    {
        label: "Subject",
        field: "document_planning.subject",
        sortable: false,
        hidden: false,
    },
    {
        label: "Project",
        field: "document_planning.project",
        sortable: false,
        hidden: false,
    },
    {
        label: "Priority",
        field: "document_planning.priority",
        sortable: false,
        hidden: false,
    },
    {
        label: "Platform",
        field: "document_planning.platform",
        sortable: false,
        hidden: false,
    },
    {
        label: "Repository",
        field: "document_planning.repository",
        sortable: false,
        hidden: false,
    },
    {
        label: "Valid",
        field: "plan_valid",
        thClass: "text-center",
        tdClass: "text-center text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Date",
        field: "tanggal",
        thClass: "text-center",
        tdClass: "text-center",
        hidden: false,
    },
    {
        label: "Action",
        field: "action",
        hidden: false,
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
    },
];

const marketing_activity = [{
        label: "#",
        field: "iteration",
        sortable: false,
        hidden: false,
    },
    {
        label: "Foto",
        field: "document.picture",
        sortable: false,
        hidden: false,
    },
    {
        label: "Rumah Sakit",
        field: "document.name_rs",
        sortable: false,
        hidden: false,
    },
    {
        label: "Tipe Survei",
        field: "document.type_visit",
        sortable: false,
        hidden: false,
    },
    {
        label: "Nama Alat",
        field: "document.name_tool",
        sortable: false,
        hidden: false,
    },
    {
        label: "Tahun Pembelian",
        field: "document.year_purchase",
        sortable: false,
        hidden: false,
    },
    {
        label: "Anggaran",
        field: "document.budget",
        sortable: false,
        hidden: false,
    },
    {
        label: "Condition",
        field: "document.condition",
        sortable: false,
        hidden: false,
    },
    {
        label: "Koordinat",
        field: "latitude",
        sortable: false,
        hidden: false,
    },
    {
        label: "Tanggal",
        field: "tanggal_only",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        hidden: false,
    },
    {
        label: "Waktu",
        field: "time_only",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        hidden: false,
    },
    {
        label: "Action",
        field: "action",
        hidden: false,
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
    },
];

export default {
    name: "Survey",
    metaInfo: {
        title: "Solusi Dental",
        titleTemplate: "Survey | %s",
    },
    data() {
        return {
            formInput: {
                date_start: moment().format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD"),
                doc_type: "",
            },
            columns: [],
            rows: [],
            propsEdit: {},
            totalRecords: 0,
            reportLayer: {}
        };
    },
    computed: {
        filterColumns() {
            return this.formInput.type_doc_planning == "it_activity" ?
                it_activity :
                marketing_activity;
        },
        isManager() {
            return this.$store.getters.levelAccess == 'MANAGER'
        }
    },
    async mounted() {
        await this.getDocType();
        await this.getData(true);
    },
    methods: {
        handleCancel() {
            this.propsEdit = {};
        },

        onChangeDate() {
            if (this.formInput.date_end && this.formInput.date_start) this.getData();
        },

        async getData(all = false) {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "report-service/document/survey/search",
                    all ? {
                        doc_type: this.formInput.doc_type,
                    } : {
                        date_from: moment(this.formInput.date_start).format(
                            "DD-MM-YYYY"
                        ),
                        date_to: moment(this.formInput.date_end).format("DD-MM-YYYY"),
                        doc_type: this.formInput.doc_type,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.formInput.date_start = moment(
                        resp.data.filter.date_from,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                    this.formInput.date_end = moment(
                        resp.data.filter.date_to,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getDocType() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.get("report-service/document/my-layer-report", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.reportLayer = resp.data.data
                    this.formInput.doc_type = resp.data.data.type_doc;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async changeColumn(column) {
            for (var i in this.columns) {
                if (this.columns[i].label == column.label) {
                    this.columns[i].hidden =
                        "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
                    break;
                }
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, 0.26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
