<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h6 class="card-title">Personal Planning</h6>
                        <h6 class="text-danger">Your Planning will automatic send on friday</h6>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <b-button-group>
                            <b-button v-b-modal.modal-form-send-email variant="outline-success">
                                <i class="fa fa-send mr-1"></i>
                                Send Planning
                            </b-button>
                            <b-button variant="outline-info" v-b-modal.modal-form>
                                <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Add Planning
                            </b-button>
                        </b-button-group>
                    </div>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="row">
                                <div class="col-sm-6 col-md-4 col-lg-3">
                                    <label>Start Date</label>
                                    <b-form-datepicker @input="onChangeDate" :date-format-options="{
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                    }" locale="id" v-model="formInput.date_start" style="border-width: initial; height: 56px !important">
                                    </b-form-datepicker>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-3">
                                    <label>End Date</label>
                                    <b-form-datepicker @input="onChangeDate" :date-format-options="{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    }" locale="id" v-model="formInput.date_end" :min="formInput.date_end" style="border-width: initial; height: 56px !important">
                                    </b-form-datepicker>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card-footer border-0 pt-0">
                    <p class="card-text d-inline h4">Total Planning: {{ rows.length }}</p>
                </div>
            </div>
        </div>
        <div class="col-12" v-if="rows.length > 0">
            <div class="card">
                <div class="card-body">
                    <vue-good-table styleClass="display mb-4 dataTable no-footer" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
              }" :rows="rows" :columns="filterColumns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button-group>
                                    <b-button title="Detail" variant="outline-info" size="sm" :to="'detail-planning?id=' + props.row.id"><i class="fa fa-search"></i> Detail
                                    </b-button>
                                    <b-button v-if="props.row.plan_valid == 0" title="Edit" variant="outline-warning" size="sm" @click="propsEdit = props.row" v-b-modal.modal-form><i class="fa fa-pencil"></i> Edit
                                    </b-button>
                                    <b-button v-if="props.row.plan_valid == 0" title="Confirm" variant="outline-success" size="sm" @click="handleConfirm(props.row.id)"><i class="fa fa-check"></i> Validate
                                    </b-button>
                                    <b-button v-if="props.row.plan_valid == 0" title="Delete" variant="outline-danger" size="sm" @click="handleDelete(props.row.id)"><i class="fa fa-trash"></i> Delete
                                    </b-button>
                                </b-button-group>
                            </div>

                            <div v-else-if="props.column.field == 'row.icon'">
                                <div v-if="props.row.approve_by !== null">
                                    <img :src="props.row.icon" class="rounded-circle mr-2" width="36" alt="Icon" />
                                </div>
                            </div>

                            <div v-else-if="props.column.field == 'iteration'">
                                {{ props.row.originalIndex + 1 }}.
                            </div>

                            <!-- <div v-else-if="props.column.field == 'confirm'">
                                <span v-if="props.row.confirm == 0" class="btn btn-sm light btn-danger">No confirmed yet</span>
                                <span v-else-if="props.row.confirm == 1" class="btn btn-sm light btn-success">Confirmed</span>
                                <span v-else class="btn btn-sm light btn-info">-</span>
                            </div> -->

                            <div v-else-if="props.column.field == 'plan_valid'">
                                <span v-if="props.row.plan_valid == 0" class="btn btn-sm light btn-danger">Invalid</span>
                                <span v-else-if="props.row.plan_valid == 1" class="btn btn-sm light btn-success">Valid</span>
                                <span v-else class="btn btn-sm light btn-info">-</span>
                            </div>

                            <div v-else-if="props.column.field == 'tanggal'">
                                <span>{{ props.row.tanggal }}, {{ props.row.time }}</span>
                            </div>

                            <div v-else-if="props.column.field == 'viewed'">
                                <div>
                                    <b-avatar-group size="40px">
                                        <b-avatar v-for="(val, id) in props.row.viewers" :key="id" :src="val.user_data.profile" :title="
                          val.user_data.first_name +
                          ' ' +
                          val.user_data.last_name
                        " variant="primary"></b-avatar>
                                    </b-avatar-group>
                                </div>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
    <form-planning :props-row="propsEdit" :type-doc="reportLayer" />
    <form-send-email :date-start="formInput.date_start" :date-end="formInput.date_end" :type-doc="reportLayer" />
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import FormPlanning from "./FormPlanning.vue";
import FormSendEmail from "./FormSendEmail.vue";

const moment = require("moment");

const it_activity = [{
        label: "#",
        field: "iteration",
        sortable: false,
        hidden: false,
    },
    {
        label: "Subject",
        field: "document_planning.subject",
        sortable: false,
        hidden: false,
    },
    {
        label: "Project",
        field: "document_planning.project",
        sortable: false,
        hidden: false,
    },
    {
        label: "Priority",
        field: "document_planning.priority",
        sortable: false,
        hidden: false,
    },
    {
        label: "Platform",
        field: "document_planning.platform",
        sortable: false,
        hidden: false,
    },
    {
        label: "Repository",
        field: "document_planning.repository",
        sortable: false,
        hidden: false,
    },
    {
        label: "Valid",
        field: "plan_valid",
        thClass: "text-center",
        tdClass: "text-center text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Date",
        field: "tanggal",
        thClass: "text-center",
        tdClass: "text-center",
        hidden: false,
    },
    {
        label: "Action",
        field: "action",
        hidden: false,
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
    },
];

const marketing_activity = [{
        label: "#",
        field: "iteration",
        sortable: false,
        hidden: false,
    },
    {
        label: "Hospital Name",
        field: "document_planning.hospital_name",
        sortable: false,
        hidden: false,
    },
    {
        label: "User",
        field: "document_planning.doctor_name",
        sortable: false,
        hidden: false,
    },
    {
        label: "Position",
        field: "document_planning.specialist",
        sortable: false,
        hidden: false,
    },
    {
        label: "Valid",
        field: "plan_valid",
        thClass: "text-center",
        tdClass: "text-center text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Date",
        field: "tanggal",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        hidden: false,
    },
    {
        label: "Action",
        field: "action",
        hidden: false,
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
    },
];

const dental_activity = [{
        label: "#",
        field: "iteration",
        sortable: false,
        hidden: false,
    },
    {
        label: "Place type",
        field: "document_planning.type_place",
        sortable: false,
        hidden: false,
    },
    {
        label: "Place Name",
        field: "document_planning.name_place",
        sortable: false,
        hidden: false,
    },
    {
        label: "PIC Name",
        field: "document_planning.name_pic",
        sortable: false,
        hidden: false,
    },
    {
        label: "Phone No",
        field: "document_planning.phone_number",
        sortable: false,
        hidden: false,
    },
    {
        label: "Address",
        field: "document_planning.address",
        sortable: false,
        hidden: false,
    },
    {
        label: "Activities",
        field: "document_planning.activities",
        sortable: false,
        hidden: false,
    },
    {
        label: "ETC",
        field: "document_planning.etc",
        sortable: false,
        hidden: false,
    },
    {
        label: "Date",
        field: "tanggal_only",
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Time",
        field: "time",
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Action",
        field: "action",
        hidden: false,
        thClass: "text-center",
        tdClass: "text-center text-nowrap",
        sortable: false,
    },
];


export default {
    name: "Planning",
    metaInfo: {
        title: "Solusi Dental",
        titleTemplate: "Planning | %s",
    },
    components: {
        FormPlanning,
        FormSendEmail,
    },
    data() {
        return {
            formInput: {
                date_start: moment().format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD"),
                type_doc_planning: "",
            },
            columns: [],
            rows: [],
            propsEdit: {},
            totalRecords: 0,
            reportLayer: {}
        };
    },
    computed: {
        isManager() {
            return this.$store.getters.levelAccess == "MANAGER";
        },
        // filterColumns() {
        //     return this.formInput.type_doc_planning == "it_activity" ?
        //         it_activity :
        //         marketing_activity;
        // },
        filterColumns() {
            if (this.formInput.type_doc_planning == "it_activity") {
                return it_activity;
            } else if (this.formInput.type_doc_planning == "marketing_activity") {
                return marketing_activity
            }
            
            return dental_activity
        },
    },
    async mounted() {
        await this.getDocType();
        await this.getData(true);
    },
    methods: {
        handleCancel() {
            this.propsEdit = {};
        },

        onChangeDate() {
            if (this.formInput.date_end && this.formInput.date_start) this.getData();
        },

        async getData(all = false) {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "report-service/document/planning/search",
                    all ? {
                        type_doc_planning: this.formInput.type_doc_planning,
                    } : {
                        date_from: moment(this.formInput.date_start).format(
                            "DD-MM-YYYY"
                        ),
                        date_to: moment(this.formInput.date_end).format("DD-MM-YYYY"),
                        type_doc_planning: this.formInput.type_doc_planning,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.formInput.date_start = moment(
                        resp.data.filter.date_from,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                    this.formInput.date_end = moment(
                        resp.data.filter.date_to,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getDocType() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.get("report-service/document/my-layer-report", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.reportLayer = resp.data.data
                    this.formInput.type_doc_planning = resp.data.data.type_doc;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async handleExportExcel() {
            try {
                await this.$store.dispatch("setLoading", true);
                // let resp = await 
                axios.post("report-service/document/my-layer-report", {
                    date_from: moment(this.formInput.date_start).format(
                        "DD-MM-YYYY"
                    ),
                    date_to: moment(this.formInput.date_end).format("DD-MM-YYYY"),
                    type_doc_planning: this.formInput.type_doc_planning,
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                // if (resp.data.error === false) {
                //     this.formInput.type_doc_planning = resp.data.data.type_doc;
                // }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async handleConfirm(id) {
            this.$swal.fire({
                title: 'Are you sure validate this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post("report-service/document/planning/confirm-valid", {
                            id: id,
                        }, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        });

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.getData()
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire(
                                    "Request ",
                                    error.response.data.message,
                                    "error"
                                );
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch("setLoading", false);
                    }
                }
            })
        },

        async handleDelete(id) {
            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post("report-service/document/planning/delete", {
                            id: id,
                        }, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        });

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.getData()
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire(
                                    "Request ",
                                    error.response.data.message,
                                    "error"
                                );
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch("setLoading", false);
                    }
                }
            })
        },

        async changeColumn(column) {
            for (var i in this.columns) {
                if (this.columns[i].label == column.label) {
                    this.columns[i].hidden =
                        "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
                    break;
                }
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, 0.26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
