<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h6 class="card-title">Team Planning</h6>
                    </div>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="row">
                                <div class="col-sm-6 col-md-4 col-lg-3">
                                    <label>Choose Your Team</label>
                                    <v-select label="first_name" :options="teams" v-model="selected.user" @input="onChangeTeam">
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                {{ option.first_name + " " + option.last_name }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                {{ option.first_name + " " + option.last_name }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-3">
                                    <label>Start Date</label>
                                    <b-form-datepicker @input="onChangeDate" :date-format-options="{
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                    }" locale="id" v-model="formInput.date_start" style="border-width: initial; height: 56px !important">
                                    </b-form-datepicker>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-3">
                                    <label>End Date</label>
                                    <b-form-datepicker @input="onChangeDate" :date-format-options="{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    }" locale="id" v-model="formInput.date_end" :min="formInput.date_end" style="border-width: initial; height: 56px !important">
                                    </b-form-datepicker>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card-footer border-0 pt-0">
                    <p class="card-text d-inline h4">Total Planning: {{ rows.length }}</p>
                </div>
            </div>
        </div>
        <div class="col-12" v-if="rows.length > 0">
            <div class="card">
                <div class="card-body">
                    <vue-good-table styleClass="display mb-4 dataTable no-footer" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
              }" :rows="rows" :columns="filterColumns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button-group>
                                    <b-button title="Detail" variant="outline-info" size="sm" :to="'detail-planning?id=' + props.row.id + '&user=' + props.row.create_by_data.id "><i class="fa fa-search"></i> Detail
                                    </b-button>
                                </b-button-group>
                            </div>

                            <div v-else-if="props.column.field == 'row.icon'">
                                <div v-if="props.row.approve_by !== null">
                                    <img :src="props.row.icon" class="rounded-circle mr-2" width="36" alt="Icon" />
                                </div>
                            </div>

                            <div v-else-if="props.column.field == 'iteration'">
                                {{ props.row.originalIndex + 1 }}.
                            </div>

                            <div v-else-if="props.column.field == 'plan_valid'">
                                <span v-if="props.row.plan_valid == 0" class="btn btn-sm light btn-danger">Invalid</span>
                                <span v-else-if="props.row.plan_valid == 1" class="btn btn-sm light btn-success">Valid</span>
                                <span v-else class="btn btn-sm light btn-info">-</span>
                            </div>

                            <div v-else-if="props.column.field == 'tanggal'">
                                <span>{{ props.row.tanggal }}, {{ props.row.time }}</span>
                            </div>

                            <div v-else-if="props.column.field == 'viewed'">
                                <div>
                                    <b-avatar-group size="40px">
                                        <b-avatar v-for="(val, id) in props.row.viewers" :key="id" :src="val.user_data.profile" :title="val.user_data.first_name + ' ' + val.user_data.last_name" variant="primary"></b-avatar>
                                    </b-avatar-group>
                                </div>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

const moment = require("moment");

const it_activity = [{
        label: "#",
        field: "iteration",
        sortable: false,
        hidden: false,
    },
    {
        label: "Subject",
        field: "document_planning.subject",
        sortable: false,
        hidden: false,
    },
    {
        label: "Project",
        field: "document_planning.project",
        sortable: false,
        hidden: false,
    },
    {
        label: "Priority",
        field: "document_planning.priority",
        sortable: false,
        hidden: false,
    },
    {
        label: "Platform",
        field: "document_planning.platform",
        sortable: false,
        hidden: false,
    },
    {
        label: "Repository",
        field: "document_planning.repository",
        sortable: false,
        hidden: false,
    },
    {
        label: "Valid",
        field: "plan_valid",
        thClass: "text-center",
        tdClass: "text-center text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Date",
        field: "tanggal",
        thClass: "text-center",
        tdClass: "text-center",
        hidden: false,
    },
    {
        label: "Action",
        field: "action",
        hidden: false,
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
    },
];

const marketing_activity = [{
        label: "#",
        field: "iteration",
        sortable: false,
        hidden: false,
    },
    {
        label: "Hospital Name",
        field: "document_planning.hospital_name",
        sortable: false,
        hidden: false,
    },
    {
        label: "User",
        field: "document_planning.doctor_name",
        sortable: false,
        hidden: false,
    },
    {
        label: "Position",
        field: "document_planning.specialist",
        sortable: false,
        hidden: false,
    },
    {
        label: "Valid",
        field: "plan_valid",
        thClass: "text-center",
        tdClass: "text-center text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Date",
        field: "tanggal",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        hidden: false,
    },
    {
        label: "Action",
        field: "action",
        hidden: false,
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
    },
];

const dental_activity = [{
        label: "#",
        field: "iteration",
        sortable: false,
        hidden: false,
    },
    {
        label: "Place type",
        field: "document_planning.type_place",
        sortable: false,
        hidden: false,
    },
    {
        label: "Place Name",
        field: "document_planning.name_place",
        sortable: false,
        hidden: false,
    },
    {
        label: "PIC Name",
        field: "document_planning.name_pic",
        sortable: false,
        hidden: false,
    },
    {
        label: "Phone No",
        field: "document_planning.phone_number",
        sortable: false,
        hidden: false,
    },
    {
        label: "Address",
        field: "document_planning.address",
        sortable: false,
        hidden: false,
    },
    {
        label: "Activities",
        field: "document_planning.activities",
        sortable: false,
        hidden: false,
    },
    {
        label: "ETC",
        field: "document_planning.etc",
        sortable: false,
        hidden: false,
    },
    {
        label: "Date",
        field: "tanggal_only",
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Time",
        field: "time",
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Action",
        field: "action",
        hidden: false,
        thClass: "text-center",
        tdClass: "text-center text-nowrap",
        sortable: false,
    },
];

export default {
    name: "TeamPlanning",
    metaInfo: {
        title: "Solusi Dental",
        titleTemplate: "Team Planning | %s",
    },
    data() {
        return {
            formInput: {
                date_start: moment().format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD"),
                type_doc_planning: "",
                id_user: "",
            },
            columns: [],
            rows: [],
            propsEdit: {},
            totalRecords: 0,
            reportLayer: {},
            teams: [],
            selected: {
                user: ''
            },
        };
    },
    computed: {
        isManager() {
            return this.$store.getters.levelAccess == "MANAGER";
        },
        filterColumns() {
            if (this.formInput.type_doc_planning == "it_activity") {
                return it_activity;
            } else if (this.formInput.type_doc_planning == "marketing_activity") {
                return marketing_activity
            }
            
            return dental_activity
        },
    },
    async mounted() {
        if (this.$store.getters.levelAccess !== 'MANAGER') {
            return this.$router.push('/');
        }

        if (this.$route.query.id) {
            this.formInput.id_user = this.$route.query.id
            await this.getDocType();
        }

        this.getTeam()
        // await this.getDocType();
        // await this.getData(true);
    },
    methods: {
        handleCancel() {
            this.propsEdit = {};
        },

        onChangeDate() {
            if (this.formInput.date_end && this.formInput.date_start) this.getData();
        },

        async onChangeTeam(val) {
            if (val === null) {
                return this.formInput.id_user = ''
            } else {
                this.formInput.id_user = val.id
            }

            await this.getDocType();
            await this.getData();
        },

        async getData() {
            try {
                if (this.formInput.id_user.length == 0) {
                    return this.$swal.fire("Warning", "Please select the team", "warning");
                }

                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post("report-service/document/planning/search", {
                    date_from: moment(this.formInput.date_start).format("DD-MM-YYYY"),
                    date_to: moment(this.formInput.date_end).format("DD-MM-YYYY"),
                    type_doc_planning: this.formInput.type_doc_planning,
                    id_user: this.formInput.id_user
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.formInput.date_start = moment(resp.data.filter.date_from, "DD-MM-YYYY").format("YYYY-MM-DD");
                    this.formInput.date_end = moment(resp.data.filter.date_to, "DD-MM-YYYY").format("YYYY-MM-DD");
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getTeam() {
            try {
                let resp = await axios.get("user-service/auth/group/my-team", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.teams = resp.data.data.users.filter(v => v.is_report_access);

                    if (this.$route.query.id) {
                        for (let index = 0; index < resp.data.data.users.length; index++) {
                            if (resp.data.data.users[index].id === this.$route.query.id) {
                                this.selected.user = resp.data.data.users[index]
                                break;
                            }
                        }
                    }
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getDocType() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.get(`report-service/document/my-layer-report?id_user=${this.formInput.id_user}`, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.formInput.type_doc_planning = resp.data.data.type_doc;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async changeColumn(column) {
            for (var i in this.columns) {
                if (this.columns[i].label == column.label) {
                    this.columns[i].hidden =
                        "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
                    break;
                }
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, 0.26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
