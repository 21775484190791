<template>
<transition name="fade" mode="out-in" appear>
    <component :is="urlQuery"></component>
</transition>
</template>

<script>
import Dashboard from '../views/ReportModule/Dashboard';
import PersonalActivityReport from "../views/ReportModule/ActivityReport/PersonalActivityReport";
import TeamActivityReport from "../views/ReportModule/ActivityReport/TeamActivityReport";
import DetailReport from "../views/ReportModule/ActivityReport/DetailReport";
import Planning from "../views/ReportModule/Planning/Planning";
import DetailPlanning from "../views/ReportModule/Planning/DetailPlanning";
import TeamPlanning from  "../views/ReportModule/Planning/TeamPlanning"
import Visit from "../views/ReportModule/Visit/Visit"
import Survey from "../views/ReportModule/Survey/Survey"
import TeamSurvey from "../views/ReportModule/Survey/TeamSurvey"
import TeamVisit from "../views/ReportModule/Visit/TeamVisit"
import DetailSurvey from "../views/ReportModule/Survey/DetailSurvey"
import DetailVisit from "../views/ReportModule/Visit/DetailVisit"

const menuReport = [{
        "id": "2",
        "id_menu": "2",
        "id_component": "2",
        "action": [
            "view",
            "create",
            "delete",
            "update"
        ],
        "component": {
            "module_name": "Report",
            "module_slug_name": "Report",
            "name": "/report/personal-activity-report",
            "slug_name": "/report/personal_activity_report"
        },
        "menu": {
            "name": "Personal Activity Report",
            "slug_name": "personal_activity_report",
            "icon": "flaticon-381-notepad-2",
            "image_icon": "https://storages.solusidental.id/media/6674101611742900-purchase-request-icon.png"
        }
    },
    {
        "id": "1",
        "id_menu": "1",
        "id_component": "1",
        "action": [
            "view",
            "create",
            "delete",
            "update"
        ],
        "component": {
            "module_name": "Report",
            "module_slug_name": "Report",
            "name": "/report/planning",
            "slug_name": "/report/planning"
        },
        "menu": {
            "name": "Personal Planning",
            "slug_name": "personal_planning",
            "icon": "flaticon-381-receive",
            "image_icon": "https://storages.solusidental.id/media/1213401611743125-receive-item-icon.png"
        }
    },
    {
        "id": "5",
        "id_menu": "3",
        "id_component": "4",
        "action": [
            "view",
            "create",
            "delete",
            "update"
        ],
        "component": {
            "module_name": "Report",
            "module_slug_name": "Report",
            "name": "/report/survey",
            "slug_name": "/report/survey"
        },
        "menu": {
            "name": "Survey",
            "slug_name": "survey",
            "icon": "flaticon-381-diploma",
            "image_icon": "https://storages.solusidental.id/media/5994401611743047-sales-order-icon.png"
        }
    },
    {
        "id": "6",
        "id_menu": "6",
        "id_component": "6",
        "action": [
            "view",
            "create",
            "delete",
            "update"
        ],
        "component": {
            "module_name": "Report",
            "module_slug_name": "Report",
            "name": "/report/visit",
            "slug_name": "/report/visit"
        },
        "menu": {
            "name": "Outlet",
            "slug_name": "outlet",
            "icon": "flaticon-381-diploma",
            "image_icon": "/assets/icon/Live-Op.png"
        }
    }
]

export default {
    name: 'Report',
    components: {
        Dashboard,
        PersonalActivityReport,
        TeamActivityReport,
        DetailReport,
        Planning,
        DetailPlanning,
        TeamPlanning,
        Visit,
        Survey,
        TeamSurvey,
        DetailSurvey,
        DetailVisit,
        TeamVisit,
    },
    async created() {
        if (!this.$store.getters.hasReportAccess) {
            this.$router.push('/');
        }

        this.$store.dispatch('setMenu', (this.isManager) ? [{
                "id": "3",
                "id_menu": "1c81ba0d-2b18-48e2-a474-9c0b4ef2fbbe",
                "id_component": "57e4ecc1-9fdc-4ca8-8929-0f9e1e8fafbf",
                "action": [
                    "view",
                    "create",
                    "delete",
                    "update"
                ],
                "component": {
                    "module_name": "Report",
                    "module_slug_name": "Report",
                    "name": "/report/dashboard",
                    "slug_name": "/report/dashboard"
                },
                "menu": {
                    "name": "Dashboard",
                    "slug_name": "dashboard",
                    "icon": "flaticon-381-home",
                    "image_icon": "https://storages.solusidental.id/media/6328821611739794-dashboard-icon.png"
                }
            },
            {
                "id": "2",
                "id_menu": "2",
                "id_component": "2",
                "action": [
                    "view",
                    "create",
                    "delete",
                    "update"
                ],
                "component": {
                    "module_name": "Report",
                    "module_slug_name": "Report",
                    "name": "/report/team-activity-report",
                    "slug_name": "/report/team_activity_report"
                },
                "menu": {
                    "name": "Team Activity Report",
                    "slug_name": "team_activity_report",
                    "icon": "flaticon-381-notepad-2",
                    "image_icon": "/assets/icon/HRM.png"
                }
            },
            {
                "id": "6",
                "id_menu": "6",
                "id_component": "6",
                "action": [
                    "view",
                    "create",
                    "delete",
                    "update"
                ],
                "component": {
                    "module_name": "Report",
                    "module_slug_name": "Report",
                    "name": "/report/team-planning",
                    "slug_name": "/report/team_planning"
                },
                "menu": {
                    "name": "Team Planning",
                    "slug_name": "team_planning",
                    "icon": "flaticon-381-notepad-2",
                    "image_icon": "/assets/icon/Recruitment-Op.png"
                }
            },
            ...menuReport
        ] : [{
            "id": "3",
            "id_menu": "1c81ba0d-2b18-48e2-a474-9c0b4ef2fbbe",
            "id_component": "57e4ecc1-9fdc-4ca8-8929-0f9e1e8fafbf",
            "action": [
                "view",
                "create",
                "delete",
                "update"
            ],
            "component": {
                "module_name": "Report",
                "module_slug_name": "Report",
                "name": "/report/dashboard",
                "slug_name": "/report/dashboard"
            },
            "menu": {
                "name": "Dashboard",
                "slug_name": "dashboard",
                "icon": "flaticon-381-home",
                "image_icon": "https://storages.solusidental.id/media/6328821611739794-dashboard-icon.png"
            }
        }, ...menuReport]);
        this.$store.dispatch("setApp");
    },
    computed: {
        urlQuery() {
            return this.$route.params.component || 'Dashboard';
        },
        isManager() {
            return this.$store.getters.levelAccess == 'MANAGER'
        }
    }
}
</script>
