<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block"></div>
        <button class="btn btn-outline-info" @click="handleGoBack" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Activity Report List
        </button>
    </div>
    <div class="row" v-if="Object.keys(shelter).length > 0">
        <div class="col-xl-3">
            <div class="row">
                <div class="col-lg-12">
                    <div class="profile card card-body px-3 pt-3 pb-0">
                        <div class="profile-head">
                            <div class="profile-info">
                                <div class="profile-photo" style="margin-top: -19px !important">
                                    <img :src="shelter.create_by_data.profile" width="100px" height="100px" style="object-fit: cover;" class="rounded-circle" alt="">
                                </div>
                                <div class="profile-details">
                                    <div class="profile-name px-3 pt-2">
                                        <h4 class="text-primary mb-0">{{ shelter.create_by_data.first_name + ' ' + shelter.create_by_data.last_name }}</h4>
                                        <p>{{ shelter.create_by_data.title }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="profile-news mb-5">
                                <h5 class="text-primary d-inline">Document Type</h5>
                                <div class="media pt-3 pb-3">
                                    <img :src="shelter.icon" alt="image" class="mr-3 rounded" style="object-fit: cover;" width="75" height="75">
                                    <div class="media-body">
                                        <h5 class="pt-4">{{ shelter.type_doc_name }}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="profile-news mb-5">
                                <h5 class="text-primary d-inline">Created Date</h5>
                                <p>{{ shelter.tanggal }}</p>
                            </div>
                            <div class="profile-news mb-5" v-if="!$route.query.user">
                                <h5 class="text-primary d-inline">Reviews ({{shelter.viewers.length}})</h5>
                                <div class="media pt-3 pb-3" v-for="(val, id) in shelter.viewers" :key="id">
                                    <img :src="val.user_data.profile" alt="image" class="mr-3" style="object-fit: cover; border-radius: 50%" width="60" height="60">
                                    <div class="media-body">
                                        <h5 class="mb-1 mt-1">{{ val.user_data.first_name + ' ' + val.user_data.last_name }}</h5>
                                        <p class="mb-0"> {{ handleRelativeDate(val.created_at) }} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-9">
            <div class="card">
                <div class="card-body" v-if="shelter.type_doc == 'marketing_activity'">
                    <!-- <div class="mb-5">
                        <h2>{{ shelter.document.title }}</h2>
                    </div> -->
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Hospital Name</h4>
                        <p class="mb-2"> {{ shelter.document.hospital_name }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">User</h4>
                        <p class="mb-2"> {{ shelter.document.doctor_name }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Position</h4>
                        <p class="mb-2"> {{ shelter.document.specialist }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Result</h4>
                        <p class="mb-2"> {{ shelter.document.notes }}</p>
                    </div>
                </div>

                <div class="card-body" v-else-if="shelter.type_doc == 'it_activity'">
                    <!-- <div class="mb-5">
                        <h2>{{ shelter.document.title }}</h2>
                    </div> -->
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Subject</h4>
                        <p class="mb-2"> {{ shelter.document.subject }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Project</h4>
                        <p class="mb-2"> {{ shelter.document.project }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Priority</h4>
                        <p class="mb-2"> {{ shelter.document.priority }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Platform</h4>
                        <p class="mb-2"> {{ shelter.document.platform }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Repository</h4>
                        <p class="mb-2"> {{ shelter.document.repository }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Result</h4>
                        <p class="mb-2"> {{ shelter.document.notes }}</p>
                    </div>
                </div>

                <div class="card-body" v-else-if="shelter.type_doc == 'dental_activity'">
                    <!-- <div class="mb-5">
                        <h2>{{ shelter.document.title }}</h2>
                    </div> -->
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Place Type</h4>
                        <p class="mb-2"> {{ shelter.document.type_place }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Place Name</h4>
                        <p class="mb-2"> {{ shelter.document.name_place }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">PIC Name</h4>
                        <p class="mb-2"> {{ shelter.document.name_pic }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Phone Number</h4>
                        <p class="mb-2"> {{ shelter.document.phone_number }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Address</h4>
                        <p class="mb-2"> {{ shelter.document.address }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Activities</h4>
                        <p class="mb-2"> {{ shelter.document.activities }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Activities</h4>
                        <p class="mb-2"> {{ shelter.document.activities }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">ETC</h4>
                        <p class="mb-2"> {{ shelter.document.etc }}</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
const moment = require("moment");
// import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "DetailReport",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Report | %s',
    },
    data() {
        return {
            shelter: {},
        };
    },
    async created() {
        if (this.$route.query.user) {
            this.handleReview()
        } else {
            this.getReviewers()
        }

        this.onLoad();
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('report');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "report-service/document/find", this.$route.query.user ? {
                        id: this.$route.query.id,
                        id_user: this.$route.query.user
                    } : {
                        id: this.$route.query.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.shelter = resp.data.data;
                }

                return this.$router.push('report');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Report", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Report ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getReviewers() {
            try {
                const resp = await axios.post(
                    "report-service/document/viewer", {
                        id: this.$route.query.id,
                        type: 'report'
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.shelter.viewers = resp.data.data;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        handleRelativeDate(date) {
            return moment(date).fromNow()
        },
        handleGoBack() {
            this.$route.query.user ? this.$router.push('team-activity-report') : this.$router.push('personal-activity-report')
        },
        handleReview() {
            try {
                axios.post(
                    "report-service/document/add-viewer", {
                        id: this.$route.query.id,
                        type: 'report'
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        }
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}

.text-detail-css {
    margin: 0;
    color: #484848;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
}

.text-title-css {
    margin-bottom: 5px;
    font-size: 16px;
    display: block;
}
</style>
