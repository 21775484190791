<script>
import {
    Bar
} from "vue-chartjs";

export default {
    extends: Bar,
    mounted() {
        this.addPlugin({
            id: 'zoomCSS',
            beforeEvent(chart, ctx) {
                // console.log(chart);
                const event = ctx;
                // Take into account CSS zoom on some parent element.
                // Zoom is used, e.g., by Reveal.js.
                var zoom = window.getComputedStyle(chart.chart.canvas.parentElement).zoom || 1;
                console.log(zoom);
                // console.log(chart.canvas.parentElement);
                event.x = event.x / 0.7;
                event.y = event.y / 0.1;

                // if (zoom != 1) {
                // }
            }
        });

        this.renderChart({
            labels: [
                "RSMH Palembang",
                "Dharmais",
                "Serang",
                "Noongan",
            ],
            datasets: [{
                label: "Most visited",
                backgroundColor: "#2781d5",
                data: [89, 170, 57, 39]
            }]
        }, {
            responsive: true,
            maintainAspectRatio: false
        });
    }
};
</script>
