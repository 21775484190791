<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block"></div>
        <button class="btn btn-outline-info" @click="handleGoBack" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Planning List
        </button>
    </div>
    <div class="row" v-if="Object.keys(shelter).length > 0">
        <div class="col-xl-3">
            <div class="row">
                <div class="col-lg-12">
                    <div class="profile card card-body px-3 pt-3 pb-0">
                        <div class="profile-head">
                            <div class="profile-info">
                                <div class="profile-photo" style="margin-top: -19px !important">
                                    <img :src="shelter.create_by_data.profile" width="100px" height="100px" style="object-fit: cover;" class="rounded-circle" alt="">
                                </div>
                                <div class="profile-details">
                                    <div class="profile-name px-3 pt-2">
                                        <h4 class="text-primary mb-0">{{ shelter.create_by_data.first_name + ' ' + shelter.create_by_data.last_name }}</h4>
                                        <p>{{ shelter.create_by_data.title }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="profile-statistics mb-5">
                                    <div class="text-center">
                                        <div class="row">
                                            <div class="col">
                                                <h2 class="text-success m-b-0" v-if="shelter.plan_valid == 1">
                                                    <i class="fa fa-check"></i>
                                                </h2>
                                                <h2 class="text-danger m-b-0" v-else>
                                                    <i class="fa fa-times"></i>
                                                </h2>
                                                <span>Valid</span>
                                            </div>
                                            <!-- <div class="col">
                                                <h2 class="text-success m-b-0" v-if="shelter.confirm == 1">
                                                    <i class="fa fa-check"></i>
                                                </h2>
                                                <h2 class="text-danger m-b-0" v-else>
                                                    <i class="fa fa-times"></i>
                                                </h2>
                                                <span>Confirmed</span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            <div class="profile-news mb-5">
                                <h5 class="text-primary d-inline">Planning Type</h5>
                                <div class="media pt-3 pb-3">
                                    <img :src="shelter.icon" alt="image" class="mr-3 rounded" style="object-fit: cover;" width="75" height="75">
                                    <div class="media-body">
                                        <h5 class="pt-4">{{ shelter.type_doc_name }}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="profile-news mb-5">
                                <h5 class="text-primary d-inline">Date</h5>
                                <p>{{ shelter.tanggal }}, {{ shelter.time }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-9">
            <div class="card">
                <div class="card-body" v-if="shelter.type_doc_planning == 'marketing_activity'">
                    <!-- <div class="mb-5">
                        <h2>{{ shelter.title }}</h2>
                    </div> -->
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Hospital Name</h4>
                        <p class="mb-2"> {{ shelter.document_planning.hospital_name }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">User</h4>
                        <p class="mb-2"> {{ shelter.document_planning.doctor_name }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Position</h4>
                        <p class="mb-2"> {{ shelter.document_planning.specialist }}</p>
                    </div>
                    <!-- <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Notes</h4>
                        <p class="mb-2"> {{ shelter.document_planning.notes }}</p>
                    </div> -->
                </div>
                <div class="card-body" v-else-if="shelter.type_doc_planning == 'it_activity'">
                    <!-- <div class="mb-5">
                        <h2>{{ shelter.document_planning.title }}</h2>
                    </div> -->
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Subject</h4>
                        <p class="mb-2"> {{ shelter.document_planning.subject }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Project</h4>
                        <p class="mb-2"> {{ shelter.document_planning.project }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Priority</h4>
                        <p class="mb-2"> {{ shelter.document_planning.priority }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Platform</h4>
                        <p class="mb-2"> {{ shelter.document_planning.platform }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Repository</h4>
                        <p class="mb-2"> {{ shelter.document_planning.repository }}</p>
                    </div>
                </div>

                <div class="card-body" v-else-if="shelter.type_doc_planning == 'dental_activity'">
                    <!-- <div class="mb-5">
                        <h2>{{ shelter.document_planning.title }}</h2>
                    </div> -->
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Place Type</h4>
                        <p class="mb-2"> {{ shelter.document_planning.type_place }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Place Name</h4>
                        <p class="mb-2"> {{ shelter.document_planning.name }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">PIC Name</h4>
                        <p class="mb-2"> {{ shelter.document_planning.name_pic }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Phone Number</h4>
                        <p class="mb-2"> {{ shelter.document_planning.phone_number }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Address</h4>
                        <p class="mb-2"> {{ shelter.document_planning.address }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Activities</h4>
                        <p class="mb-2"> {{ shelter.document_planning.activities }}</p>
                    </div>
                    <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Purpose</h4>
                        <p class="mb-2"> {{ shelter.document_planning.etc }}</p>
                    </div>
                    <!-- <div class="profile-skills mb-5">
                        <h4 class="text-primary mb-2">Notes</h4>
                        <p class="mb-2"> {{ shelter.document_planning.notes }}</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
const moment = require("moment");
// import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "DetailPlanning",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Planning | %s',
    },
    data() {
        return {
            shelter: {},
        };
    },
    async created() {
        if (this.$route.query.user) {
            this.handleReview()
        }
        this.onLoad();
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('report');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "report-service/document/planning/find", this.$route.query.user ? {
                        id: this.$route.query.id,
                        id_user: this.$route.query.user
                    } : {
                        id: this.$route.query.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.shelter = resp.data.data;
                }

                return this.$router.push('report');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Planning", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Planning ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        handleRelativeDate(date) {
            return moment(date).fromNow()
        },
        handleGoBack() {
            this.$route.query.user ? this.$router.push('team-planning') : this.$router.push('planning')
        },
        handleReview() {
            try {
                axios.post(
                    "report-service/document/add-viewer", {
                        id: this.$route.query.id,
                        type: 'plan'
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        }
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}

.text-detail-css {
    margin: 0;
    color: #484848;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
}

.text-title-css {
    margin-bottom: 5px;
    font-size: 16px;
    display: block;
}
</style>
