<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h6 class="card-title">Personal Activity Report</h6>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <b-button-group>
                            <b-button v-b-modal.modal-form-send-email variant="outline-success">
                                <i class="fa fa-send mr-1"></i>
                                Send Activity Report
                            </b-button>
                        </b-button-group>
                    </div>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="row">
                                <div class="col-sm-6 col-md-4 col-lg-3">
                                    <label>Start Date</label>
                                    <b-form-datepicker @input="onChangeDate" :date-format-options="{
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                    }" locale="id" v-model="formInput.date_start" style="border-width: initial; height: 56px !important">
                                    </b-form-datepicker>
                                </div>
                                <div class="col-sm-6 col-md-4 col-lg-3">
                                    <label>End Date</label>
                                    <b-form-datepicker @input="onChangeDate" :date-format-options="{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    }" locale="id" v-model="formInput.date_end" :min="formInput.date_end" style="border-width: initial; height: 56px !important">
                                    </b-form-datepicker>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card-footer border-0 pt-0">
                    <p class="card-text d-inline h4">Total Document: {{ rows.length }}</p>
                </div>
            </div>
        </div>
        <div class="col-12" v-if="rows.length > 0">
            <div class="card">
                <div class="card-body">
                    <vue-good-table styleClass="display mb-4 dataTable no-footer" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
              }" :rows="rows" :columns="filterColumns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button title="Detail" variant="outline-info" size="sm" :to="'detail-report?id=' + props.row.id"><i class="fa fa-search"></i> Detail
                                </b-button>
                            </div>

                            <div v-else-if="props.column.field == 'row.icon'">
                                <div v-if="props.row.approve_by !== null">
                                    <img :src="props.row.icon" class="rounded-circle mr-2" width="36" alt="Icon" />
                                </div>
                            </div>

                            <div v-else-if="props.column.field == 'iteration'">
                                {{ props.row.originalIndex + 1 }}.
                            </div>

                            <div v-else-if="props.column.field == 'viewed'">
                                <div>
                                    <b-avatar-group size="40px">
                                        <b-avatar v-for="(val, id) in props.row.viewers" :key="id" :src="val.user_data.profile" :title="
                          val.user_data.first_name +
                          ' ' +
                          val.user_data.last_name
                        " variant="primary"></b-avatar>
                                    </b-avatar-group>
                                </div>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
    <form-send-email :date-start="formInput.date_start" :date-end="formInput.date_end" :type-doc="reportLayer" />
</div>
</template>

<script>
import axios from "axios";
import FormSendEmail from  "./FormSendEmail"
import "bootstrap-vue/dist/bootstrap-vue.css";

const moment = require("moment");

const it_activity = [{
        label: "#",
        field: "iteration",
        sortable: false,
        hidden: false,
    },
    {
        label: "Subject",
        field: "document.subject",
        sortable: false,
        hidden: false,
    },
    {
        label: "Project",
        field: "document.project",
        sortable: false,
        hidden: false,
    },
    {
        label: "Priority",
        field: "document.priority",
        sortable: false,
        hidden: false,
    },
    {
        label: "Platform",
        field: "document.platform",
        sortable: false,
        hidden: false,
    },
    {
        label: "Repository",
        field: "document.repository",
        sortable: false,
        hidden: false,
    },
    {
        label: "Result",
        field: "document.notes",
        sortable: false,
        hidden: false,
    },
    {
        label: "Viewer",
        field: "viewed",
        sortable: false,
        hidden: false,
    },
    {
        label: "Date",
        field: "tanggal_only",
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Time",
        field: "time",
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Action",
        field: "action",
        hidden: false,
        thClass: "text-center",
        tdClass: "text-center text-nowrap",
        sortable: false,
    },
];

const marketing_activity = [{
        label: "#",
        field: "iteration",
        sortable: false,
        hidden: false,
    },
    {
        label: "Hospital Name",
        field: "document.hospital_name",
        sortable: false,
        hidden: false,
    },
    {
        label: "User",
        field: "document.doctor_name",
        sortable: false,
        hidden: false,
    },
    {
        label: "Position",
        field: "document.specialist",
        sortable: false,
        hidden: false,
    },
    {
        label: "Viewer",
        field: "viewed",
        sortable: false,
        hidden: false,
    },
    {
        label: "Result",
        field: "document.notes",
        sortable: false,
        hidden: false,
    },
    {
        label: "Date",
        field: "tanggal_only",
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Time",
        field: "time",
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Action",
        field: "action",
        hidden: false,
        thClass: "text-center",
        tdClass: "text-center text-nowrap",
        sortable: false,
    },
];

const dental_activity = [{
        label: "#",
        field: "iteration",
        sortable: false,
        hidden: false,
    },
    {
        label: "Place type",
        field: "document.type_place",
        sortable: false,
        hidden: false,
    },
    {
        label: "Place Name",
        field: "document.name_place",
        sortable: false,
        hidden: false,
    },
    {
        label: "PIC Name",
        field: "document.name_pic",
        sortable: false,
        hidden: false,
    },
    {
        label: "Phone No",
        field: "document.phone_number",
        sortable: false,
        hidden: false,
    },
    {
        label: "Address",
        field: "document.address",
        sortable: false,
        hidden: false,
    },
    {
        label: "Activities",
        field: "document.activities",
        sortable: false,
        hidden: false,
    },
    {
        label: "ETC",
        field: "document.etc",
        sortable: false,
        hidden: false,
    },
    {
        label: "Date",
        field: "tanggal_only",
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Time",
        field: "time",
        thClass: "text-center",
        tdClass: "text-left text-nowrap",
        sortable: false,
        hidden: false,
    },
    {
        label: "Action",
        field: "action",
        hidden: false,
        thClass: "text-center",
        tdClass: "text-center text-nowrap",
        sortable: false,
    },
];

export default {
    name: "PersonalActivityReport",
    metaInfo: {
        title: "Solusi Dental",
        titleTemplate: "Personal Activity Report | %s",
    },
    components: {
        FormSendEmail
    },
    data() {
        return {
            formInput: {
                date_start: moment().format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD"),
                doc_type: "",
            },
            columns: [],
            rows: [],
            propsEdit: {},
            totalRecords: 0,
            reportLayer: {}
        };
    },
    computed: {
        isManager() {
            return this.$store.getters.levelAccess == "MANAGER";
        },
        filterColumns() {
            if (this.formInput.doc_type == "it_activity") {
                return it_activity;
            } else if (this.formInput.doc_type == "marketing_activity") {
                return marketing_activity
            }
            
            return dental_activity
        },
    },
    async mounted() {
        await this.getDocType();
        await this.getData(true);
    },
    methods: {
        handleCancel() {
            this.propsEdit = {};
        },
        
        onChangeDate() {
            if (this.formInput.date_end && this.formInput.date_start) this.getData();
        },

        async getData(all = false) {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "report-service/document/search",
                    all ? {
                        doc_type: this.formInput.doc_type,
                    } : {
                        date_from: moment(this.formInput.date_start).format(
                            "DD-MM-YYYY"
                        ),
                        date_to: moment(this.formInput.date_end).format("DD-MM-YYYY"),
                        doc_type: this.formInput.doc_type,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.formInput.date_start = moment(
                        resp.data.filter.date_from,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                    this.formInput.date_end = moment(
                        resp.data.filter.date_to,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getDocType() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.get("report-service/document/my-layer-report", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.reportLayer = resp.data.data
                    this.formInput.doc_type = resp.data.data.type_doc;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async handleExportExcel() {
            try {
                await this.$store.dispatch("setLoading", true);
                // let resp = await 
                axios.post("report-service/document/my-layer-report", {
                    date_from: moment(this.formInput.date_start).format(
                        "DD-MM-YYYY"
                    ),
                    date_to: moment(this.formInput.date_end).format("DD-MM-YYYY"),
                    doc_type: this.formInput.doc_type,
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                // if (resp.data.error === false) {
                //     this.formInput.doc_type = resp.data.data.type_doc;
                // }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async changeColumn(column) {
            for (var i in this.columns) {
                if (this.columns[i].label == column.label) {
                    this.columns[i].hidden =
                        "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
                    break;
                }
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, 0.26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
