<template>
<div class="container-fluid">
    <MenuApp />
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-xl-4 col-md-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="card text-center cursor-pointer"><img src="/assets/images/image1.svg" class="card-img-top">
                                <div class="card-body">
                                    <div class="col-12">
                                        <div class="widget-stat card bg-info">
                                            <div class="card-body  p-4">
                                                <div class="media">
                                                    <span class="mr-3">
                                                        <i class="flaticon-381-notepad-1"></i>
                                                    </span>
                                                    <div class="media-body text-white text-right">
                                                        <p class="mb-1">Total Report</p>
                                                        <h3 class="text-white">{{ totalReport }}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" v-if="isManager">
                                        <div class="widget-stat card bg-warning">
                                            <div class="card-body  p-4">
                                                <div class="media">
                                                    <span class="mr-3">
                                                        <i class="flaticon-381-user-1"></i>
                                                    </span>
                                                    <div class="media-body text-white text-right">
                                                        <p class="mb-1">Total Team</p>
                                                        <h3 class="text-white">{{ totalTeam }}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-12">
                    <div class="col-12">
                        <div class="card card-developer-meetup">
                            <div class="card-header">
                                <h4>Your Planning</h4>
                            </div>
                            <div class="bg-light-primary rounded-top text-center"><img src="/assets/images/task.svg" alt="Meeting Pic" height="170" class=""></div>
                            <div class="card-body" v-if="planning.length > 0">
                                <div style="margin-bottom: 2rem;" class="d-flex align-items-center">
                                    <div class="meetup-day" style="border-right: 1px solid #d8d6de;padding-right: 1.3rem;margin-right: 1.3rem;">
                                        <h6 class="mb-0"> {{ nameOfDay(planning[0].document_planning.tanggal) }} </h6>
                                        <h3 class="mb-0 text-center"> {{ numberOfDay(planning[0].document_planning.tanggal) }} </h3>
                                    </div>
                                    <div class="my-auto">
                                        <h4 class="card-title mb-10">
                                            <router-link :to="'detail-planning?id=' + planning[0].id">
                                                {{ planning[0].document_planning.hospital_name }}
                                            </router-link>
                                        </h4>
                                        <p class="card-text mb-0"> {{ planning[0].document_planning.doctor_name }} | {{ planning[0].document_planning.waktu }}</p>
                                    </div>
                                </div>
                                <div class="media mb-1" v-for="(value, index) in planning.slice(1)" :key="index">
                                    <div class="media-aside mr-1 align-self-start"><span class="b-avatar badge-light-primary rounded" style="width: 34px; height: 34px;"><span class="b-avatar-custom"><svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar">
                                                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                    <line x1="16" y1="2" x2="16" y2="6"></line>
                                                    <line x1="8" y1="2" x2="8" y2="6"></line>
                                                    <line x1="3" y1="10" x2="21" y2="10"></line>
                                                </svg></span>
                                            <!----></span></div>
                                    <div class="media-body">
                                        <h6 class="mb-0">
                                            <router-link :to="'detail-planning?id=' + value.id">
                                                {{ value.document_planning.hospital_name }}
                                            </router-link>
                                        </h6>
                                        <small> {{ nameOfDay(value.document_planning.tanggal) }}, {{ value.document_planning.date_time }} </small>
                                    </div>
                                </div>
                            </div>
                            <div align="center" class="card-body mt-3" v-else>
                                <span class="w-space-no">No Data</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="isManager ? 'col-xl-6 col-md-12' : 'col-xl-6 col-md-12'">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Recent Personal Report</h4>
                        </div>
                        <div class="card-body">
                            <vue-good-table styleClass="display mb-4 dataTable no-footer" :pagination-options="{
                                    enabled: false,
                                    dropdownAllowAll: false,
                                    nextLabel: 'Next',
                                    prevLabel: 'Previous',
                                    position: 'top',
                                }" :rows="recent.rows" :columns="recent.columns" compactMode>
                                <template slot="table-row" slot-scope="props">
                                    <div v-if="props.column.field == 'action'">
                                        {{ props.row.id }}
                                    </div>

                                    <div v-else-if="props.column.field == 'iteration'">
                                        {{ props.row.originalIndex + 1 }}.
                                    </div>

                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6" v-if="isManager">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Your Team</h4>
                        </div>
                        <div class="card-body">
                            <vue-good-table styleClass="display mb-4 dataTable no-footer" :pagination-options="{
                                    enabled: true,
                                    dropdownAllowAll: false,
                                    nextLabel: 'Next',
                                    prevLabel: 'Previous',
                                    position: 'top',
                                }" :rows="team.rows" :columns="team.columns" compactMode @on-row-click="handleTeamClicked">
                                <template slot="table-row" slot-scope="props">
                                    <div v-if="props.column.field == 'iteration'">
                                        {{ props.row.originalIndex + 1 }}.
                                    </div>
                                    <div style="cursor: pointer" v-else-if="props.column.field == 'first_name'">
                                        <img :src="props.row.profile" class="rounded-circle mr-2" style="object-fit: cover;" width="36" height="36" alt="Profile" />
                                        <span class="w-space-no">{{ props.row.first_name }} {{ props.row.last_name }}</span>
                                    </div>
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4>Most visited</h4>
                        </div>
                        <div class="card-body">
                            <chart-total-visit />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import axios from 'axios'
import MenuApp from '../../components/MenuApp';
import ChartTotalVisit from './ChartTotalVisit';

export default {
    name: "Dashboard",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Dashboard Report | %s',
    },
    components: {
        MenuApp,
        ChartTotalVisit
    },
    computed: {
        isManager() {
            return this.$store.getters.levelAccess == 'MANAGER'
        }
    },
    data() {
        return {
            totalReport: 0,
            totalTeam: 0,
            recent: {
                columns: [{
                        label: "#",
                        field: "iteration",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: "Title",
                        field: "title",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: "Date",
                        field: "tanggal",
                        sortable: false,
                        hidden: false,
                    },
                ],
                rows: [],
                propsEdit: {},
                totalRecords: 0,
                doc_type: ''
            },
            planning: {},
            team: {
                columns: [{
                        label: "#",
                        field: "iteration",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: "Name",
                        field: "first_name",
                        sortable: false,
                        hidden: false,
                    },
                    // {
                    //     label: "Level",
                    //     field: "level_access",
                    //     sortable: false,
                    //     hidden: false,
                    // },
                    {
                        label: "Total Report",
                        field: "total_report",
                        sortable: false,
                        hidden: false,
                    },
                ],
                rows: [],
                totalRecords: 0,
            }
        }
    },
    async mounted() {
        await this.$store.dispatch("setApp");
        await this.$store.dispatch('setLoading', true);
        await this.getTotalReport()
        await this.getTotalTeam()
        await this.getData();
        await this.getTeam();
        await this.getIncomingPlanning();
        await this.$store.dispatch('setLoading', false);
    },

    methods: {
        nameOfDay(dateString) {
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var d = new Date(dateString);
            return days[d.getDay()];
        },

        numberOfDay(dateString) {
            var d = new Date(dateString);
            return d.getUTCDate()
        },

        async getTotalReport() {
            try {
                let resp = await axios.get('report-service/document/count', {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalReport = resp.data.data.total || 0;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getTotalTeam() {
            try {
                let resp = await axios.get('user-service/auth/group/my-team', {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalTeam = resp.data.data.users.length || 0;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getData() {
            try {
                let resp = await axios.get(
                    "report-service/document/recent?limit=15", {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.recent.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getTeam() {
            try {
                let resp = await axios.get(
                    "user-service/auth/group/my-team", {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.team.rows = resp.data.data.users;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getIncomingPlanning() {
            try {
                let resp = await axios.post(
                    "report-service/document/planning/incoming", {}, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.planning = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire(
                            "Request ",
                            error.response.data.message,
                            "error"
                        );
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async handleTeamClicked(event) {
            this.$router.push('team-activity-report?id=' + event.row.id)

        }

    }
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, 0.26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
